import {appLanguage, titleNormalized} from "./select";
import {LangageError} from "./language";

const getText = (key) => {
    return LangageError[key][appLanguage]
}

export const error = [
    {
        "uid": -101,
        "brief": getText('no_related'),
        "question": getText('no_related'),
        "min_len": 2
    },
    {
        "uid": -102,
        "brief": getText('moderation'),
        "question": getText('moderation'),
        "min_len": 2
    },

]
