export const saveResWidget = (message, setState ) => {
    const save = {
        message: message,
        type: 'widget',
        id: new Date().getTime()
    }

    setState(prevState => ({
      ...prevState,
      messages: [...prevState.messages, save]
    }))
  }

export const capitalizeFirstLetter = (inputString) => {
  if(inputString == null)
    return;
  
    if (inputString.length === 0) {
      return inputString;
    }
    return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
  }
