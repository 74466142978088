import { appLanguage, titleNormalized } from "./select";
import { LangageHair } from "./language";

const getText = (key) => {
    return LangageHair[key][appLanguage]
}

export const haireQt = [
    {
        "uid": 0,
        "brief": getText('brief_type'),
        "question": getText('hair_type'),
        "choices": [
            { "text": getText('steep'), "img": 'steep' },
            { "text": getText('corrugated'), "img": 'corrugated' },
            { "text": getText('looped'), "img": 'looped' },
            { "text": getText('curly'), "img": 'curly' },
            { "text": getText('frizzy'), "img": 'frizzy' },
            { "text": getText('other'), "img": "Autre" , "value": " " }
        ],
        "widget": "multiChoices",
        "min_len": 5,
        "delay": 6000,
        "withAvatar": false,
        "sendMsg" : true
    },
    {
        "uid": 1,
        "brief": getText('brief_thick'),
        "question": getText('hair_thickness'),
        "choices": [
            { "text": getText('fins'), "img": "Fin" },
            { "text": getText('normals'), "img": "Naturel" },
            { "text": getText('thick'), "img": "épai" }
        ],
        "widget": "multiChoices",
        "min_len": 4,
        "sendMsg" : true
    },
    {
        "uid": 2,
        "brief": "Longueur cheveux: ",
        "question": getText('length'),
        "choices": [
            { "text": getText('short'), "img": "shorts" },
            { "text": getText('mi_long'), "img": "Mi-longs" },
            { "text": getText('long'), "img": "longs" },
            { "text": getText('bald'), "img": "bald" }
        ],
        "widget": "multiChoices",
        "min_len": 4,
        "sendMsg" : true
    },
    {
        "uid": 4,
        "brief": getText('brief_scalp'),
        "question": getText('scalp'),
        "choices": [
            { "text": getText('sec'), "img": 'sec' },
            { "text": getText('normal'), "img": 'scalp_normal' },
            { "text": getText('gras'), "img": 'gras' },
            { "text": getText('irritated'), "img": 'irritated' },
            { "text": getText('none'), "img": 'scalp_none' }
        ],
        "widget": "multiChoices",
        "min_len": 3,
        "sendMsg" : true
    },
    {
        "uid": 3,
        "brief": getText('brief_color'),
        "question": getText('color'),
        "choices": [
            { "text": getText('blond'), "img": "Blonds" },
            { "text": getText('light_blond'), "img": "Chatains" },
            { "text": getText('brown'), "img": "Bruns" },
            { "text": getText('red'), "img": "Roux" },
            { "text": getText('colorful'), "img": "Colorés" },
            { "text": getText('highlighted'), "img": "Méchés VF" },
            { "text": getText('white_hair'), "img": "Gris _ Blanc " }

        ],
        "widget": "choices",
        "min_len": 5,
        "sendMsg" : true
    },
    {
        "uid": 5,
        "brief": getText("dandruff_brief"),
        "question": getText('dandruff'),
        "choices": [
            { "text": getText('yes'), "value": getText("have_dandruff") },
            { "text": getText('no'), "value": " " },
        ],
        "widget": "multiChoices",
        "min_len": 2,
        "sendMsg" : true
    },
    {
        "uid": 5,
        "brief": "Etat des cheveux",
        "question": getText('qualified_hairdresser'),
        "choices": [
            { "text": getText('damaged') },
            { "text": getText('breakage')},
            { "text": getText('forked')},
            { "text": getText('dehydrate')},
            { "text": getText('perfect')},
            { "text": getText('weak')},
        ],
        "widget": "choices",
        "min_len": 2,
        "sendMsg" : true
    },
    {
        "uid": 6,
        "brief": getText("hair_loss_brief"),
        "question": getText('hair_loss'),
        "choices": [
            { "text": getText('yes'), "value": getText("have_hair_loss") },
            { "text": getText('no'), "value": "" },
        ],
        "widget": "multiChoices",
        "min_len": 2,
        "sendMsg" : true
    },
    {
        "uid": 4,
        "brief": getText('brief_soin'),
        "question": getText('hair_care'),
        "choices": [
            { "text": getText('protect_coloration') },
            { "text": getText('protect_straighteningIron') },
            { "text": getText('volume') },
            { "text": getText('smoothing') },
            { "text": getText('loops') },
            { "text": getText('shine') },
            { "text": getText('growth') },
            { "text": getText('strengthen') },
            { "text": getText('appease') },
            { "text": getText('hydrate') },
            { "text": getText('protect_coloration') },
            { "text": getText('repair') },
            { "text": getText('sebum') }
        ],
        "min_len": 15,
        "widget": "choices",
        "sendMsg" : true
    },
   /*  {
        "uid": 4,
        "brief": getText('brief_soin'),
        "question": getText('hair_care'),
        "choices": [
            { "text": getText('growth') },
            { "text": getText('strengthen') },
            { "text": getText('appease') },
            { "text": getText('hydrate') },
            { "text": getText('protect_coloration') },
            { "text": getText('repair') },
            { "text": getText('sebum') },
        ],
        "min_len": 15,
        "widget": "choices",
        "sendMsg" : true
    }, */
   /*  {
        "uid": 3,
        "brief": getText('brief_beauty'),
        "question": getText('goals_beauty'),
        "choices": [
            { "text": getText('volume') },
            { "text": getText('smoothing') },
            { "text": getText('loops') },
            { "text": getText('shine') }
        ],
        "min_len": 15,
        "widget": "choices",
        "sendMsg" : true
    }, */
    {
        "uid": 7,
        "brief": getText("brief_expectation"),
        "question": getText('expectation'),
        "choices": [
            { "text": getText("bio"), "img": "Bio" },
            { "text": getText("naturals"), "img": "Produits_naturels" },
            { "text": getText("made_FR"), "img": "Fabrication_française" },
            { "text": getText("others"), "img": "Autre" }
        ],
        "widget": "choices",
        "min_len": 2,
        "sendMsg" : true
    },
    {
        "uid": 8,
        "question": getText('take_final_selfie'),
        "choices": [
            { "text": 'prendre Les selfies', "next": 5 },
            { "text": "Démarrer le chat", "next": 5 }
        ],
        "widget": "selfieOrChat",
        "sendMsg" : true
    },
    {
        "uid": 9,
        "brief": "",
        "question": getText('recommend_products'),
        "choices": [{"text" : getText('see_selection')}],
        "widget": "multiChoices",
        "min_len": 0,
        "sendMsg" : false
    }
]
